import { Box, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useLOB, useLocale } from '@scfhp-web/shared-module';
import { LinkComponent } from 'components/custom-link.component';
import { PATHS } from 'commons/constants';
import googlePlay from 'assets/play-store-icon.png';
import appleIcon from 'assets/apple-icon.png';
import {
  FooterLinkProps,
  footerLinksConfig,
  footerSubLinksConfig,
} from '../../utils/footer-link-list';
import { AppStoreLink } from './app-store-link.component';

export const FooterComponent = () => {
  const { language } = useLocale();
  const lob = useLOB();
  const linkItems = footerLinksConfig(lob, language);
  const subLinkItems = footerSubLinksConfig();
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      css={{
        background: '$decorativeBrandPrimaryPastel',
        flexWrap: 'wrap',
        paddingTop: '$three',
        paddingBottom: '$twoAndHalf',
        marginTop: '$three',
        '@laptop': {
          flexWrap: 'nowrap',
        },
        '@desktop': {
          flexWrap: 'nowrap',
        },
      }}
    >
      <MainPageContainer
        paddingX={{ _: 'oneAndHalf', phone: 'two' }}
        width="100%"
      >
        <StackLayout
          orientation="horizontal"
          verticalAlignment="center"
          horizontalAlignment="spaceBetween"
          css={{
            '@mobile': {
              flexDirection: 'column',
              alignItems: 'center',
            },
            '@laptop': {
              flexDirection: 'row',
            },
          }}
        >
          <StackLayout
            orientation="vertical"
            verticalAlignment="center"
            horizontalAlignment="center"
            css={{
              flexShrink: 0,
              flex: 0,
              marginRight: '$oneAndHalf',
            }}
          >
            <AppStoreLink
              href={PATHS.IOS_APP_LINK}
              imgSrc={appleIcon}
              altText="Apple Store"
            />
            <AppStoreLink
              href={PATHS.ANDRIOD_APP_LINK}
              imgSrc={googlePlay}
              altText="Google Play Store"
            />
          </StackLayout>

          <StackLayout
            orientation="vertical"
            verticalAlignment="center"
            css={{
              flexGrow: 1,
              '@mobile': {
                marginTop: '$two',
              },
              '@laptop': {
                flexDirection: 'row',
                marginTop: '0',
              },
            }}
          >
            <Box>
              {linkItems?.map((item: FooterLinkProps) => (
                <LinkComponent key={item.text} {...item} />
              ))}
            </Box>
            <Box
              css={{
                margin: '$one 0 $oneAndHalf 0',
              }}
            >
              {subLinkItems?.map((item: FooterLinkProps) => (
                <LinkComponent key={item.text} {...item} />
              ))}
            </Box>
            <UtilityText size="xs" css={{ marginLeft: '$one' }}>
              {formatMessage({ id: 'STR_COPYRIGHT_TEXT' })}
            </UtilityText>
          </StackLayout>
        </StackLayout>
      </MainPageContainer>
    </StackLayout>
  );
};
