import { useEffect } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useLOB } from '@scfhp-web/shared-module';

interface NavigationParams {
  pathname: string;
  search: string;
}

const useConditionalNavigation = (): void => {
  const history = useHistory();
  const lob = useLOB();

  useEffect(() => {
    const navigateTo = (path: string, isReplace: boolean = false): void => {
      const currentFullPath = window.location.pathname + window.location.search;
      if (currentFullPath !== path) {
        if (isReplace) {
          history.replace(path);
        } else {
          history.push(path);
        }
      }
    };

    const handleNavigation = ({ pathname, search }: NavigationParams): void => {
      const lastRelevantPath =
        sessionStorage.getItem('lastRelevantPath') || '/';

      let modifiedPathname = pathname;

      if (modifiedPathname.includes('/member')) {
        modifiedPathname = modifiedPathname.replace('/member', '');
        navigateTo(modifiedPathname + search, true);
      }

      if (modifiedPathname.includes('/about-me')) {
        navigateTo('/dashboard', true);
      }

      if (modifiedPathname === '/achievements') {
        navigateTo('/dashboard?view=achievements', true);
      }

      if (modifiedPathname.includes('openLink')) {
        const rawQueryString = window.location.search;
        const cleanedQueryString = rawQueryString.replace(/&amp;/g, '&');

        const queryParams = new URLSearchParams(cleanedQueryString);

        const linkType = queryParams.get('link');
        if (linkType === 'activity') {
          const activityId =
            queryParams.get('activityId') || queryParams.get('amp;activityId');
          const campaignId =
            queryParams.get('campaignId') || queryParams.get('amp;campaignId');

          if (activityId && campaignId) {
            const targetPath = `/health-journey/campaign/${campaignId}/activity/${activityId}/toolbox`;
            navigateTo(targetPath, lastRelevantPath === '/forms');
          }
        }
      }

      sessionStorage.setItem('lastRelevantPath', modifiedPathname);
    };

    const unlisten = history.listen(handleNavigation);

    return () => {
      unlisten();
    };
  }, [history, lob]);
};

export default useConditionalNavigation;
